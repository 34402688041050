//import React from 'react';
import coverImg from '../../utils/cover-img.jpg';
import './success.css';
import Loader from '../loader/Loader';
import { useTranslation } from 'react-i18next';

//lottie animation JSONs
import Lottie from 'lottie-react';
import animate1 from '../../utils/order_placed_back_animation.json';
import animate2 from '../../utils/order_success_tick_animation.json';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { setCart, setCartCheckout, setCartProducts, setCartPromo, setCartSubTotal, setWallet } from '../../model/reducer/cartReducer';



const Success = () => {

    const setting = useSelector(state => state.setting);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
   // const bankOrderCode = queryParams.get('bankOrderCode');
    const reference = queryParams.get('reference');
    const user = useSelector(state => (state.user));
    const dispatch = useDispatch();
    const placeHolderImage = (e) => {
        e.target.src = setting.setting?.web_logo;
    };
    const handleClose = () => {
       
        navigate('/');
    };
    /*const clearCart=()=>{
        //api.removeCart(user?.jwtToken);
        dispatch(setCartPromo({ data: null }));
        dispatch(setCartProducts({ data: [] }));
        dispatch(setCartSubTotal({ data: 0 }));
        dispatch(setCart({ data: null }));
        dispatch(setCartCheckout({ data: null }));
    }*/
    //clearCart();
    return (
        <section id='success' className='success'>
            {setting.status === 'loading' || setting.setting === null
                ? (
                    <Loader screen='full' />
                )
                : (
                    <>
                        <div className='cover'>
                            <img src={coverImg} className='img-fluid' alt="cover" onError={placeHolderImage}></img>
                            <div className='title'>
                                <h3>{t("Pago exitoso")}</h3>
                                <span> <Link to="/" className='text-light text-decoration-none'>{t("home")} /</Link> </span><span className='active'>{t("Pago exitoso")}</span>
                            </div>
                        </div>
                        <div className='container'>
    <div className='success_modal'style={{ marginTop: "100px" }} >
        
        <div className='flex-column-reverse success_header' >
        
            <div className='lottie-tick' style={{ margin: "0 auto" }}>
            <Lottie className='lottie-content' animationData={animate1} loop={true} ></Lottie>
                <Lottie animationData={animate2} loop={false}></Lottie>
            </div>
        </div>
        <div className='success_body d-flex flex-column justify-content-center align-items-center'>
            <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "100px" }}>
                <p><h1>¡Gracias por su compra! 🎉</h1></p>
                <p>Desde <strong><a href="https://okisss.com">Okisss.com</a></strong> esperamos que disfrute de sus productos y le agradecemos la confianza.</p>
                <p>Para rastrear su orden use los siguientes detalles:</p>
                {/* <p><strong>Código de Orden del Banco:</strong> {bankOrderCode}</p> */}
                <p><strong>C&oacute;digo de referencia:# {reference}</strong></p>
                <br></br>
                <br></br>
                <p>Con gratitud: El equipo de <strong><a href="https://okisss.com">Okisss.com</a></strong></p>
            </div>
            <button onClick={handleClose} type="button" className='checkout_btn'  style={{ marginBottom: "100px", height:"40px" }}>
                {t("go_to_home")}
            </button>
        </div>
    </div>
</div>
                    </>

                )}
        </section>
    );
};

export default Success;
