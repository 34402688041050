import React, { useEffect, useState, useMemo } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import api from "../../api/api";
import "./address.css";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Loader from "../loader/Loader";
import {
  setAddress,
  setSelectedAddress,
} from "../../model/reducer/addressReducer";
import { BiCurrentLocation } from "react-icons/bi";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const NewAddress = (props) => {
  const dispatch = useDispatch();

  const setting = useSelector((state) => state.setting);
  const user = useSelector((state) => state.user);

  const address = useSelector((state) => state.address);
  const city = useSelector((state) => state.city);

  const [addressDetails, setaddressDetails] = useState({
    name: "",
    mobile_num: "",
    alternate_mobile_num: "",
    carne: "",
    address: "",
    landmark: "",
    city: "",
    area: "",
    pincode: "",
    state: "",
    country: "",
    address_type: "Home",
    is_default: false,
  });

  

  const [error, setError] = useState("");
  const handleConfirmAddress = () => {
    if (!validatePhoneNumber(addressDetails.mobile_num)) {
      setError(
        "El teléfono debe incluir el código del país usando (+) y tener un formato válido."
      );
      return;
    }
    if (addressDetails.alternate_mobile_num) {
      if (!validatePhoneNumber(addressDetails.alternate_mobile_num)) {
        setError(
          "El teléfono alternativo debe incluir el código del país usando (+) y tener un formato válido."
        );
        return;
      }
    }
    let lat = localLocation.lat; //center.lat;
    let lng = localLocation.lng; //center.lng;
    if (lat === 0 || lng === 0 || lat === citilocation.lat || lng === citilocation.lng) {
      setError(
        "Debe ubicar la dirección en el mapa para obtener las coodenadas"
      );
      return;
    }
    let pincode = -1; //addressDetails.pincode;
    if (!props.isAddressSelected) {
      props.setisLoader(true);
      api.addAddress(
          user?.jwtToken,
          addressDetails.name,
          addressDetails.carne,
          addressDetails.mobile_num,
          addressDetails.address_type,
          addressDetails.address,
          addressDetails.landmark,
          addressDetails.area,
          pincode,
          addressDetails.city,
          addressDetails.state,
          addressDetails.country,
          addressDetails.alternate_mobile_num,
          lat,
          lng,
          addressDetails.is_default
        )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 1) {
            toast.success("Dirección añadida!");
            setaddressDetails({
              name: "",
              carne: "",
              mobile_num: "",
              alternate_mobile_num: "",
              address: "",
              landmark: "",
              city: "",
              area: "",
              pincode: "",
              state: "",
              country: "",
              address_type: "Home",
              is_default: false,
            });
            api
              .getAddress(user?.jwtToken)
              .then((resp) => resp.json())
              .then((res) => {
                props.setisLoader(false);
                if (res.status === 1) {
                  dispatch(setAddress({ data: res.data }));
                  // dispatch({ type: ActionTypes.SET_ADDRESS, payload: res.data });
                }
                if (res.data.find((element) => element.is_default == 1)) {
                  dispatch(
                    setSelectedAddress({
                      data: res.data.find((element) => element.is_default == 1),
                    })
                  );
                  // dispatch({ type: ActionTypes.SET_SELECTED_ADDRESS, payload: res.data.find((element) => element.is_default == 1) });
                }
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));
    } else {
      props.setisLoader(true);
      api
        .updateAddress(
          user?.jwtToken,
          address.selected_address.id,
          addressDetails.name,
          addressDetails.carne,
          addressDetails.mobile_num,
          addressDetails.address_type,
          addressDetails.address,
          addressDetails.landmark,
          addressDetails.area,
          pincode,
          addressDetails.city,
          addressDetails.state,
          addressDetails.country,
          addressDetails.alternate_mobile_num,
          lat,
          lng,
          addressDetails.is_default
        )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 1) {
            toast.success("Dirección actualizada!");
            props.setIsAddressSelected(false);
            setaddressDetails({
              name: "",
              carne: "",
              mobile_num: "",
              alternate_mobile_num: "",
              address: "",
              landmark: "",
              city: "",
              area: "",
              pincode: "",
              state: "",
              country: "",
              address_type: "Home",
              is_default: false,
            });
            setisconfirmAddress(false);
            api
              .getAddress(user?.jwtToken)
              .then((resp) => resp.json())
              .then((res) => {
                props.setisLoader(false);
                if (res.status === 1) {
                  // dispatch({ type: ActionTypes.SET_ADDRESS, payload: res.data });
                  dispatch(setAddress({ data: res.data }));
                }
                if (result.data.find((element) => element.is_default == 1)) {
                  dispatch(
                    setSelectedAddress({
                      data: res.data.find((element) => element.is_default == 1),
                    })
                  );
                  //dispatch({ type: ActionTypes.SET_SELECTED_ADDRESS, payload: result.data.find((element) => element.is_default == 1) });
                }
              })
              .catch((error) => console.log(error));
          }
        })
        .catch((error) => console.log(error));
    }

    props.setshow(false);
  };
  const validatePhoneNumber = (phoneNumber) => {
    console.info("El numero de telefono que viene: " + phoneNumber);
    const parsedNumber = parsePhoneNumberFromString(phoneNumber + "");
    if (!parsedNumber) {
      return false;
    }
    return parsedNumber.isValid();
  };

  useEffect(() => {
    if (props.isAddressSelected && address.selected_address) {
      setaddressDetails({
        name: address.selected_address.name,
        carne: address.selected_address.carne,
        mobile_num: address.selected_address.mobile,
        alternate_mobile_num: address.selected_address.alternate_mobile,
        address: address.selected_address.address,
        landmark: address.selected_address.landmark,
        city: address.selected_address.city,
        area: address.selected_address.area,
        pincode: address.selected_address.pincode,
        state: address.selected_address.state,
        country: address.selected_address.country,
        address_type: address.selected_address.type,
        is_default: address.selected_address.is_default === 1 ? true : false,
      });
    }
    if (!props.isAddressSelected) {
      console.info(
        "No hay direccion seleccionada se puso por defecto la ubicacion de la ciudad"
      );
      setlocalLocation({
        lat: parseFloat(city.city ? city.city.latitude : 0),
        lng: parseFloat(city.city ? city.city.longitude : 0),
      });
    } else {
      console.info(
        "Si hay direccion seleccionada: Lat:" +
          address.selected_address.latitude +
          " long:" +
          address.selected_address.longitude
      );
      setlocalLocation({
        lat: parseFloat(address ? address.selected_address.latitude : 0),
        lng: parseFloat(address ? address.selected_address.longitude : 0),
      });
    }
  }, [props.isAddressSelected, address?.selected_address]);

  const [isconfirmAddress, setisconfirmAddress] = useState(false);
  
  const [localLocation, setlocalLocation] = useState({
    lat: parseFloat(address?.selected_address?.latitude ?? city.city?.latitude ?? 0),
    lng: parseFloat(address?.selected_address?.longitude ?? city.city?.longitude ?? 0),
  });
  const [addressLoading, setaddressLoading] = useState(false);
  // const center = {
  //   lat: parseFloat(address ? address?.selected_address.latitude : 0),
  //   lng: parseFloat(address ? address?.selected_address.longitude : 0),
  // };
  const citilocation = {
      lat: parseFloat(city.city ? city.city.latitude : 0),
      lng: parseFloat(city.city ? city.city.longitude : 0),
  };

  // useEffect(() => {
  //     if (addressDetails.address !== '') {

  //         // const geocoder = new window.google.maps.Geocoder();
  //         const fullAddress = `${addressDetails.address}, ${addressDetails.city}, ${addressDetails.state}, ${addressDetails.country}`;
  //         console.info('La direccion completa es:', fullAddress);
  //         // geocoder.geocode({ address: fullAddress }, (results, status) => {
  //         //      if (status === 'OK') {
  //         //          const location = results[0].geometry.location;
  //         //         setlocalLocation({ lat: location.lat(), lng: location.lng() });
  //         //     } else {
  //         //         console.error('Geocode was not successful for the following reason:', status);
  //         //      }
  //         //  });
  //     }
  // }, [addressDetails]);

  const onMarkerDragStart = () => {
    //  setaddressLoading(true);
    // console.info(
    //   " la latidud  incicial:" +
    //     parseFloat(localLocation.lat) +
    //     "la logintud inicial: " +
    //     parseFloat(localLocation.lng)
    // );
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    // Actualiza la ubicación local inmediatamente
    setlocalLocation({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    });

    // Luego, realiza la geocodificación para obtener la dirección
    const geocoder = new window.google.maps.Geocoder();
    geocoder
      .geocode({
        location: { lat, lng },
      })
      .then((response) => {
        if (response.results[0]) {
          console.info("La latitud: " + lat + " La longitud: " + lng);
          // Puedes actualizar la dirección u otros detalles aquí si es necesario
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { t } = useTranslation();

  const mapContainerStyle = {
    width: "100%",
    height: window.innerWidth > 990 ? "100%" : "400px",
  };
  const handleCurrentLocationClick = () => {
      navigator.geolocation.getCurrentPosition(
          (position) => {
              const latLng = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
              };
              setlocalLocation({
                  lat: parseFloat(latLng.lat),
                  lng: parseFloat(latLng.lng),
              });
              console.info(" Tu ubicacion es: lat:"+latLng.lat+ "  long:"+latLng.lng);
             
          });
  };

  return (
    <>
      <Modal
        className="new-address"
        show={props.show}
        backdrop="static"
        keyboard={true}
        size="xl"
      >
        <Modal.Header>
          <div className="d-flex flex-row justify-content-between header w-100 align-items-center">
            <h5>{t("new_address")}</h5>
            <button
              type="button"
              className=""
              onClick={() => {
                setaddressDetails({
                  name: "",
                  carne: "",
                  mobile_num: "",
                  alternate_mobile_num: "",
                  address: "",
                  landmark: "",
                  city: "",
                  area: "",
                  // pincode: '',
                  state: "",
                  country: "",
                  address_type: "Home",
                  is_default: false,
                });
                props.setshow(false);
                setisconfirmAddress(false);
                props.setIsAddressSelected(false);
              }}
              style={{ width: "30px" }}
            >
              <AiOutlineCloseCircle />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="flex d-lg-flex flex-row gap-5">
            {
              <div className="w-100 mapWrapper">
                <button
                  whiletap={{ scale: 0.6 }}
                  className="current-location-button"
                   onClick={handleCurrentLocationClick}
                >
                  <BiCurrentLocation
                    className="mx-3"
                    width="40px"
                    height="40px"
                  />
                </button>
                <GoogleMap
                  zoom={10}
                   center={localLocation}
                  position={{ lat: localLocation.lat, lng: localLocation.lng }}
                  mapContainerStyle={mapContainerStyle}
                  className="map-marker"
                >
                  <MarkerF
                  
                    position={{ lat: localLocation.lat, lng: localLocation.lng }}
                    draggable={true}
                    onDragStart={onMarkerDragStart}
                    onDragEnd={onMarkerDragEnd}
                  ></MarkerF>
                </GoogleMap>
              </div>
            }

            {addressLoading ? (
              <div className="d-flex justify-content-center">
                <Loader width="570px" height="675px" />
              </div>
            ) : (
              <>
                <div className="">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleConfirmAddress();
                    }}
                    className="address-details-wrapper"
                  >
                    {
                      <div className="contact-detail-container">
                        <h3>{t("contact_details")}</h3>
                        <div className="contact-details">
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder={t("Name")}
                            value={addressDetails.name}
                            required
                            onChange={(e) => {
                              setaddressDetails((state) => ({
                                ...state,
                                name: e.target.value,
                              }));
                              setError("");
                            }}
                          ></input>
                          {/* onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} */}
                          <input
                            required
                            type="text"
                            style={{ width: "100%" }}
                            placeholder={t("Carne o Pasaporte")}
                            value={addressDetails.carne}
                            onChange={(e) => {
                              setError("");
                              // console.info("el carne es: "+ e.target.value);
                              setaddressDetails((state) => ({
                                ...state,
                                carne:e.target.value,
                              }));

                            }}
                          />
                          <input
                            required
                            type="text"
                            style={{ width: "100%" }}
                            placeholder={t("mobile_number")}
                            value={addressDetails.mobile_num}
                            onChange={(e) => {
                              setError("");
                             
                              setaddressDetails((state) => ({
                                ...state,
                                mobile_num: e.target.value,
                              }));

                            }}
                          />

                          {/* onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} */}
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            placeholder={t("alt_mobile_no")}
                            value={addressDetails.alternate_mobile_num}
                            onChange={(e) => {
                           
                              setaddressDetails((state) => ({
                                ...state,
                                alternate_mobile_num: e.target.value,
                              }));
                            
                            }}
                          ></input>
                          {error && (
                            <span className="user_data_form_error">
                              {error}
                            </span>
                          )}
                        </div>
                      </div>
                    }

                    <div className="address-detail-container">
                      <h3>{t("address_details")}</h3>
                      <div className="address-details">
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder={t("address")}
                          value={addressDetails.address}
                          onChange={(e) => {
                            setaddressDetails((state) => ({
                              ...state,
                              address: e.target.value,
                            }));
                          }}
                          required
                        ></input>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder={t("enter_landmark")}
                          value={addressDetails.landmark}
                          onChange={(e) => {
                            setaddressDetails((state) => ({
                              ...state,
                              landmark: e.target.value,
                            }));
                          }}
                          required
                        ></input>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder={t("enter_area")}
                          value={addressDetails.area}
                          onChange={(e) => {
                            setaddressDetails((state) => ({
                              ...state,
                              area: e.target.value,
                            }));
                          }}
                          required
                        ></input>
                        {/* { <input type='text' style={{ width: "100%" }} placeholder={t('enter_pincode')} value={addressDetails.pincode} onChange={(e) => {

                                                    setaddressDetails(state => ({ ...state, pincode: e.target.value }));
                                                }} required></input> } */}
                        <input
                          type="text"
                          className=""
                          style={{ width: "100%" }}
                          required
                          value={addressDetails.city}
                          placeholder={t("enter_city")}
                          onChange={(e) =>
                            setaddressDetails((state) => ({
                              ...state,
                              city: e.target.value,
                            }))
                          }
                        ></input>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder={t("enter_state")}
                          value={addressDetails.state}
                          onChange={(e) => {
                            setaddressDetails((state) => ({
                              ...state,
                              state: e.target.value,
                            }));
                          }}
                          required
                        ></input>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder={t("enter_country")}
                          value={addressDetails.country}
                          onChange={(e) => {
                            setaddressDetails((state) => ({
                              ...state,
                              country: e.target.value,
                            }));
                          }}
                          required
                        ></input>
                      </div>
                    </div>

                    <div className="address-type-container">
                      <h3>{t("address_type")}</h3>
                      <div className="address-type">
                        <input
                          type="radio"
                          name="address-type"
                          id="home-address"
                          onChange={() => {
                            setaddressDetails((state) => ({
                              ...state,
                              address_type: "Home",
                            }));
                          }}
                          autoComplete="off"
                          checked={addressDetails.address_type === "Home"}
                          defaultChecked={true}
                        />
                        <label htmlFor="home-address">
                          {t("adress_type_home")}
                        </label>

                        {/* <input type='radio' name='address-type' id='office-address' onChange={() => {
                                                    setaddressDetails(state => ({ ...state, address_type: 'Office' }));
                                                }} autoComplete='off' /> */}
                        <input
                          type="radio"
                          name="address-type"
                          id="office-address"
                          onChange={() => {
                            setaddressDetails((state) => ({
                              ...state,
                              address_type: "Office",
                            }));
                          }}
                          autoComplete="off"
                          checked={addressDetails.address_type === "Office"}
                        />
                        <label htmlFor="office-address">
                          {t("address_type_office")}
                        </label>

                        <input
                          type="radio"
                          name="address-type"
                          id="other-address"
                          onChange={() => {
                            setaddressDetails((state) => ({
                              ...state,
                              address_type: "Other",
                            }));
                          }}
                          autoComplete="off"
                          checked={addressDetails.address_type === "Other"}
                        />
                        <label htmlFor="other-address">
                          {t("address_type_other")}
                        </label>
                      </div>
                      <div className="default-address">
                        <input
                          type="checkbox"
                          className="mx-2"
                          defaultValue={true}
                          onChange={() => {
                            setaddressDetails((state) => ({
                              ...state,
                              is_default: !addressDetails.is_default,
                            }));
                          }}
                          checked={addressDetails.is_default}
                        />
                        {t("set_as_default_address")}
                      </div>
                    </div>

                    <button type="submit" className="confirm-address">
                      {t("confirm_location")}
                    </button>
                  </form>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewAddress;
