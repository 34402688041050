//import React from 'react';
import coverImg from '../../utils/cover-img.jpg';
import './success.css';
import Loader from '../loader/Loader';
import { useTranslation } from 'react-i18next';

//lottie animation JSONs
import Lottie from 'lottie-react';
import animate2 from '../../utils/failanimation.json';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../api/api';
import {  useSelector } from 'react-redux';



const Fail = () => {

    const setting = useSelector(state => state.setting);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
   // const bankOrderCode = queryParams.get('bankOrderCode');
    const reference = queryParams.get('reference');
    const placeHolderImage = (e) => {
        e.target.src = setting.setting?.web_logo;
    };
    const handleClose = () => {
        navigate('/');
    };
    
    return (
        <section id='success' className='success'>
            {setting.status === 'loading' || setting.setting === null
                ? (
                    <Loader screen='full' />
                )
                : (
                    <>
                        <div className='cover'>
                            <img src={coverImg} className='img-fluid' alt="cover" onError={placeHolderImage}></img>
                            <div className='title'>
                                <h3>{t("Error en el pago")}</h3>
                                <span> <Link to="/" className='text-light text-decoration-none'>{t("home")} /</Link> </span><span className='active'>{t("Pago exitoso")}</span>
                            </div>
                        </div>
                        <div className='container'>
    <div className='success_modal'style={{ marginTop: "100px" }} >
        <div className='flex-column-reverse success_header' >
            <div className='lottie-tick' style={{ margin: "0 auto" }}>
            <Lottie animationData={animate2} loop={false}></Lottie>
            </div>
        </div>
        <div className='success_body d-flex flex-column justify-content-center align-items-center'>
            <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "100px" }}>
                <p><h1>¡Ocurri&oacute; un error al pagar! </h1></p>
                <p>Por favor compruebe :</p>
                <ul>
                   <li><strong>Fondos insuficientes:</strong> La cuenta del cliente no tiene suficiente dinero para cubrir la compra.</li>
                   <li><strong>Información incorrecta:</strong> Datos como el número de tarjeta, fecha de vencimiento o código CVV son ingresados incorrectamente.</li>
                </ul>
                <br></br>
                <br></br>
                <p>Si desea comunicarse con nosotros puede escribirnos al correo <a href='mailto:info@okiss.com'>info@okiss.com </a> 
                o al WhatsApp <a href='https://api.whatsapp.com/send?phone=5358472911&text=Hola,%20tengo%20la%20siguiente%20duda'>+5358472911</a></p>
            </div>
            <button onClick={handleClose} type="button" className='checkout_btn'  style={{ marginBottom: "100px", height:"40px" }}>
                {t("go_to_home")}
            </button>
        </div>
    </div>
</div>
                    </>

                )}
        </section>
    );
};

export default Fail;
