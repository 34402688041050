import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './model/store';
import { Provider } from 'react-redux';
import { Persiststore } from './model/store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./utils/i18n.js";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={Persiststore}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch((error) => {
      // console.log(error);
    });
  });
}
const getAllCookies = () => {
  const cookies = document.cookie.split('; ');
  const cookieObject = {};
  // console.info("todas la cokkies:" +cookies);
  cookies.forEach(cookie => {
    const [name, value] = cookie.split('=');
    cookieObject[name] = value;
  });
 
  return cookieObject;
};
const setEvaluateTimeCookie = () => {
  const creationTime = new Date().toISOString();
  document.cookie = "evaluatetime="+creationTime+";path=/"  ;
};

const deleteAllCookies = () => {
  const cookies = document.cookie.split('; ');

  cookies.forEach(cookie => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  });
  document.cookie = 'egrocery-1d4fe.firebaseapp.com=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  document.cookie = 'egrocery-1d4fe.firebaseapp.com=; Max-Age=-99999999;'; 
  document.cookie = "egrocery-1d4fe.firebaseapp.com=;expires=" + new Date(0).toUTCString()

};
const checkEvaluateTimeCookie = () => {
  const cookies = getAllCookies();
  const evaluateTimeCookie = cookies['evaluatetime'];

  if (evaluateTimeCookie) {
    const creationTime = new Date(evaluateTimeCookie).getTime();
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - creationTime;

    // 24 horas en milisegundos
    const twentyFourHours = 24 * 60 * 60 * 1000;
    // const hoursElapsed = timeElapsed / (1000 * 60 * 60);
    if (timeElapsed >= twentyFourHours) {
    //if (hoursElapsed >= 0.05) {
      deleteAllCookies();
      setEvaluateTimeCookie();
      // console.log('Todas las cookies han sido eliminadas y la cookie evaluatetime ha sido actualizada.');
    } else {
      
      // const hoursRemaining = 24 - hoursElapsed;
      // console.log("La cookie evaluatetime lleva creada: "+ hoursElapsed.toFixed(2) +"horas.");
      // console.log("Faltan "+hoursRemaining.toFixed(2)+"  horas para que la cookie alcance las 24 horas: ");
    }
  } else {
    setEvaluateTimeCookie();
    // console.log('La cookie evaluatetime ha sido creada.');
  }
};

// Llama a la función para verificar y gestionar la cookie 'evaluatetime' cada vez que se accede al sitio web
checkEvaluateTimeCookie();

